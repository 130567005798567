let utils = {
    reset(obj){
      let newObj = {}
      for(var i in obj) {
        newObj[i] = ''
      }
      return newObj
    },
    stringSort(string){
      if(string != null){
        let str = string.split(',')
        str.sort()
        return str.toString()
      }
      else{
        return string
      }
    },
    //秒转时分秒
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时

      if(theTime >= 60) {
        theTime1 = parseInt(theTime/60);
        theTime = parseInt(theTime%60);

        if(theTime1 > 60) {
          theTime2 = parseInt(theTime1/60);
          theTime1 = parseInt(theTime1%60);
        }
      }
      var result = ""+parseInt(theTime)+"秒";
      if(theTime1 > 0) {
      result = ""+parseInt(theTime1)+"分"+result;
      }
      if(theTime2 > 0) {
      result = ""+parseInt(theTime2)+"小时"+result;
      }
      return result;
    },
    
}
export default utils