/** init domain config */
import Vue from "vue";
//设置全局API_BASE_URL
Vue.prototype.API_BASE_URL = process.env.VUE_APP_BASE_URL;
Vue.prototype.APP_IMG_PREFIX = process.env.VUE_APP_IMG_PREFIX;
window._CONFIG["domainURL"] = Vue.prototype.API_BASE_URL;
window._CONFIG["imgPrefix"] = Vue.prototype.APP_IMG_PREFIX;

//定义文件下载方式
window._CONFIG['downloadType'] = "cloud";
//项目阶段修改完需要高亮，高亮ID
window._CONFIG['highlightId'] = 0;
