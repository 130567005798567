import request from "@/utils/request";

// /dashboard/home/search
export function seachCount(params) {
    return request({
        url: "/dashboard/home/search",
        method: 'post',
        data: params
    })
}

//用户登录信息
export function seachInfo(params) {
    return request({
        url: "/dashboard/home/getDetail",
        method: 'post',
        data: params
    })
}

//获取消息
export function getMessageList(params) {
  return request({
      url: "/support/notify/unreadMessage",
      method: 'post',
      data: params
  })
}

//查询消息未读数量
export function unreadMessageCount(params) {
  return request({
      url: "/support/notify/unreadMessageCount",
      method: 'post',
      data: params
  })
}
//获取消息
export function getMessageQueryList(params) {
    return request({
        url: "/support/notify/list",
        method: 'post',
        data: params
    })
}
//获取全部消息列表
export function getMessageQueryAllList(params) {
  return request({
      url: "/support/notify/allMessage",
      method: 'post',
      data: params
  })
}
//消息一键已读
export function readMsg(params) {
  return request({
      url: "/support/send/readAll",
      method: 'put',
      data: params
  })
}
//消息列表详情
export function getMessageDetail(params) {
  return request({
      url: "/support/notify/detail/"+params,
      method: 'post',
      data: {}
  })
}
//获取帮助列表
export function getHelpDicList(params) {
    return request({
        url: "/help/helpDicList",
        method: 'post',
        data: params
    })
}

// /help/detail/{id}
export function getHelpDetail(params) {
    return request({
        url: `/help/detail/${params.id}`,
        method: 'get',
        data: params
    })
}

export function getSysLevelVersion(params) {
    return request({
        url: `/config/sysLevelversion/list`,
        method: 'POST',
        data: params
    })
}

// 文件下载
export function fileList(params) {
  return request({
      url: "/project/commonFile/file-download-path",
      method: 'post',
      data: params
  })
}