<template>
  <a-modal
      title="密码修改"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @keyup.enter.native="dataFormSubmit()">
        <a-form-item label="旧密码">
            <a-input-password placeholder="请输入旧密码" v-decorator="['password', { initialValue:'', rules: [{ required: true, message: '请输入旧密码' } ] }]" />
        </a-form-item>
        <a-form-item label="新密码">
            <a-input-password placeholder="请输入新密码" v-decorator="['newPassword', { initialValue:'', rules: [{ required: true, message: '请输入登陆密码' }, { trigger: 'blur' } ] }]" />
        </a-form-item>
       <a-form-item label="确认密码">
            <a-input-password placeholder="请输入密码" v-decorator="['repeatPassword', { initialValue:'', rules: [{ required: true, message: '请输入登陆密码' }, { validator: validateRepeatPassword, trigger: 'blur' } ] }]" />
        </a-form-item>
    </a-form>
    <span slot="footer" class="dialog-footer">
        <a-button @click="visible = false">取消</a-button>
        <a-button class="ant-btn ant-btn-primary" @click="dataFormSubmit()">确定</a-button>
    </span>
    </a-modal>
</template>
<script>
import {updatePass} from '@/httpsAPI/personal/index'

export default {
 data(){
     return{
        newPassword:'',
        repeatPassword:'',
        form: this.$form.createForm(this),
        visible: false,
        confirmLoading:false,
     }

 },
    methods:{
         async validateRepeatPassword (rule, value, callback) {
           console
            if (this.newPassword !== this.repeatPassword) {
                callback(new Error('密码不一致'))
            } else {
                callback()
            }
         },
          
        dataFormSubmit(e){
            
            this.form.validateFields((err, values) => {
                if (err) {            
                    return;
                }
                updatePass(values).then(res=>{
                    this.confirmLoading = true
                    if(res.code == '11111'){
                      this.$message.error({
                        content: res.msg,
                        duration: 1,
                        })
                    }else{
                       this.$message.success({
                          content: res.msg,
                          duration: 1,
                          onClose: () => {
                          this.form.resetFields();
                          this.visible = false;
                          this.$emit('refreshDataList')
                            }
                        }) 
                    }
                })                 
            });
            
        },
        handleCancel () {
        this.close()
        },
        close () {
            this.$emit('close');
            this.visible = false;
        },
        init() {
            this.visible = true
            this.$nextTick(() => { 
                this.form.resetFields();
            });
        },
    },
}
</script>

<style>

</style>