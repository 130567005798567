<template>
  <div id="app">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
   </keep-alive>
    <a-config-provider :locale="locale" v-if="!$route.meta.keepAlive">
      <router-view/>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
  name: 'App',
  data(){
    return {
      locale: zhCN,
    }
  },
}
</script>
