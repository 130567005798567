import request from "@/utils/request";

//用户登录信息
export function getuserInfo(params) {
  return request({
    url: "/user/profile/currentUser",
    method: 'post',
    data: params
  })
}
//修改密码
export function updatePass(params) {
  return request({
    url: "/user/profile/password",
    method: 'post',
    data: params
  })
}
// 更改当前用户信息
export function updateUser(params) {
  return request({
    url: "/user/profile/updateById",
    method: 'post',
    data: params
  })
}
//获取登录手机短信验证码
export function smsVerification(params) {
  return request({
    url: "/user/verification/smsVerification/" + params,
    method: 'get',
    data: {}
  })
}
//验证码登录
export function smsVerificationLogin(params) {
  return request({
    url: "/user/verification/smsVerificationLogin",
    method: 'post',
    data: params
  })
}
//更换手机号短信验证码
export function updateSmsVerification(params) {
  return request({
    url: "/user/profile/user/updateSmsVerification",
    method: 'get',
    data: params
  })
}
//更换手机号
export function updatePhone(params) {
  return request({
    url: "/user/profile/user/updatePhone",
    method: 'post',
    data: params
  })
}
//忘记密码
export function updateSmsPassWord(params) {
  return request({
    url: "/user/verification/updateSmsPassWord",
    method: 'post',
    data: params
  })
}