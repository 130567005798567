import "./config/index";

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "@/router";
import { VueAxios } from "@/utils/request";
import VCharts from 'v-charts';
import echarts from "echarts";
import Antd from "ant-design-vue";
import layout from "@/components/layout/layout";
import STable from "@/components/Table";
import Print from 'vue-print-nb'
import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
import "ant-design-vue/dist/antd.less";
import "./global.less";
import "./permission";
import "./config/action";
import "./config/permit";
import VueAliplayerV2 from "vue-aliplayer-v2";
import 'tinymce/skins/ui/oxide/skin.css'
import uploader from 'vue-simple-uploader';
import 'v-charts/lib/style.css'
import moment from 'moment'
import 'moment/locale/zh-cn'
import utils from './utils/util'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import {
  OverlayScrollbarsPlugin
} from 'overlayscrollbars-vue'


Vue.prototype.$utils = utils
Vue.use(uploader);
Vue.use(OverlayScrollbarsPlugin)
Vue.use(VueAliplayerV2);
Vue.config.productionTip = false;
Vue.use(VueAxios);
Vue.use(Antd);
Vue.use(VCharts)
Vue.component("layout", layout);
Vue.component("STable", STable);
Vue.use(VXETable);
Vue.use(Print)
moment.locale('zh-cn');
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts

if (process.env.NODE_ENV === "development") {
  //require("./mock");
}
Vue.prototype.$imgBaseUrl = process.env.VUE_APP_IMG_PREFIX
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");  
