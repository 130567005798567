<template>
  <a-modal
    title="新建项目"
    width="850px"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="()=>{$emit('cancel')}"
    destroyOnClose
  >
    <a-row>
      <a-col :md="14">
        <a-form-model ref="addForm" :model="form" :rules="rules" :label-col="{span:6}" :wrapper-col="{span:18}">
          <a-form-model-item label="项目名称" prop="projectName">
            <a-input v-model="form.projectName"/>
          </a-form-model-item>
          <a-form-model-item label="负责人" prop="masterId">
            <a-select
                :filter-option="false" 
                show-search
                :allowClear="true"
                placeholder="请选择或搜索负责人"
                v-model="form.masterId"
                @search="handleSearch"
                >
                    <a-select-option v-for="item in masterList" v-bind:key="item.id" :value="item.id">
                        {{item.name }}
                    </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-item label="起止时间">
            <a-range-picker style="width:100%" format="YYYY-MM-DD" v-decorator="['timeRange']" @change="dateChange">
                        <a-icon slot="suffixIcon" type="calendar" />
            </a-range-picker>
          </a-form-item>
        </a-form-model>
        <a-tabs :animated="false" :default-active-key="currentTab" @change="changeTemplate">
          <a-tab-pane key="1" tab="选择模板">
            <a-list :data-source="templateSelect">
              <a-list-item slot="renderItem" slot-scope="item,index" @click="checkedTemplate(index)" :class="{'choose':chooseIndex===index}">
                <a-list-item-meta
                  :description="item.description"
                >
                  <template slot="title">{{item.templateName}}</template>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
          </a-tab-pane>
          <a-tab-pane key="2" tab="从已有项目配置">
            <a-form layout="inline">
              <a-form-item label="选择要复制的项目：">
                <a-select style="width: 190px;"
                  :filter-option="false" 
                  show-search
                  :allowClear="true"
                  v-model="selectCheck"
                  placeholder="请选择或搜索项目"
                  @search="projectHandleSearch"
                 >
                  <a-select-option  v-for="item in projectSelect" :key="item.id">{{ item.projectName }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-form>
            <!--
            <div>默认复制的数据</div>
            <div>项目组件、工作向类型、权限配置、迭代配置、项目配置</div>
            <div>自定义复制的数据</div>
            <a-checkbox-group v-model="checkedList" :options="plainOptions" />
            -->
          </a-tab-pane>
        </a-tabs>
      </a-col>
      <a-col :md="10">
        <a-row>
          <img :src="tplImg" style="width:100%;height:100%;margin-left: 10px">
        </a-row>
        <a-row>
          <span>{{tplContent}}</span>
        </a-row>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import {getCompanyUserListAll} from "@/httpsAPI/common";
import {getProjectTemplateSelect,getProjectSelect} from "@/httpsAPI/project";

export default {
  name: "addProject",
  props:{
    visible:{
      type:Boolean,
      default:false
    },
    confirmLoading:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      templateSelect:[],
      chooseIndex:0,
      tplImg :'',
      tplContent: '',
      projectSelect:[],
      checkedList:[],
      templateId:"",
      currentTab:'1',
      scrollPage: 1,
      masterData: [],
      keyword: '',
      masterList: [],
      plainOptions:[
        {value:'工作项数据',label:'工作项数据'},
        {value:'迭代数据',label:'迭代数据'},
        {value:'项目计划',label:'项目计划'},
        {value:'项目成员',label:'项目成员'},
        {value:'项目报表',label:'项目报表'},
        {value:'交付物',label:'交付物（评审点上传的交付物）'},
        {value:'文件',label:'文件 （工作项内上传的文件）'},
        {value:'评审点',label:'评审点（包含目标交付物）'}
      ],
      timeRange:[],
      startTime: '',
      endTime: '',
      selectCheck:null,
      form:{
        projectName:"",
      },
      rules:{
        projectName:[
          {required:true,message:'请输入项目名称',trigger: 'change'}
        ],
        masterId:[
          {required:true,message:'请输入负责人',trigger: 'change'}
        ]
      }
    }
  },
  methods:{
    init(){

    },
    dateChange(date, dateString) {
      this.startTime = dateString[0]
      this.endTime = dateString[1]
    },
    // 下拉框 分页 搜索 负责人
    changeMasterList() {
      getCompanyUserListAll({name: this.keyword})
        .then(res => {
            this.masterList = res.data;
            this.masterData = res.data;
        })
    },
    //负责人下拉框滚动事件
    handlePopupScroll(e) {
        const { target } = e
        const scrollHeight = target.scrollHeight - target.scrollTop
        const clientHeight = target.clientHeight
        // 下拉框不下拉的时候
        if (scrollHeight === 0 && clientHeight === 0) {
            this.scrollPage = 1
        } else {
            // 当下拉框滚动条到达底部的时候
            if (scrollHeight < clientHeight + 5) {
                this.pageData(1);
            }else if (scrollHeight == 3208) {
                this.pageData(2);
            }
        }
    },
    // pageData(type) {
    //     if (this.masterData.totalPage < this.scrollPage) {
    //         this.scrollPage = this.masterData.totalPage;
    //     } else {
    //         type == 1 ? this.scrollPage++ : this.scrollPage--;
    //         this.changeMasterList();
    //         let newData = [];
    //         let max = this.masterData.totalCount;
    //         this.masterList.forEach((item, index) => {
    //         //当data数组的下标小于max时
    //         if (index < max) {
    //             newData.push(item)
    //         }
    //         })
    //         this.masterList = newData;
    //     }
    // },
    handleSearch(keyword){
      this.keyword = keyword;
      this.changeMasterList();
    },

    //项目下拉框滚动事件
    projectHandlePopupScroll(e) {
        const { target } = e
        const scrollHeight = target.scrollHeight - target.scrollTop
        const clientHeight = target.clientHeight
        // 下拉框不下拉的时候
        if (scrollHeight === 0 && clientHeight === 0) {
            this.scrollPage = 1
        } else {
            // 当下拉框滚动条到达底部的时候
            if (scrollHeight < clientHeight + 5) {
                this.projectPageData(1);
            }else if (scrollHeight == 3208) {
                this.projectPageData(2);
            }
        }
    },
    // projectPageData(type) {
    //     if (this.masterData.totalPage < this.scrollPage) {
    //         this.scrollPage = this.masterData.totalPage;
    //     } else {
    //         type == 1 ? this.scrollPage++ : this.scrollPage--;
    //         this.getProjectSelectHandle();
    //         let newData = [];
    //         let max = this.masterData.totalCount;
    //         this.projectSelect.forEach((item, index) => {
    //         //当data数组的下标小于max时
    //         if (index < max) {
    //             newData.push(item)
    //         }
    //         })
    //         this.projectSelect = newData;
    //     }
    // },
    projectHandleSearch(keyword){
      this.keyword = keyword;
      this.getProjectSelectHandle();
    },
    handleOk(){
      this.$refs.addForm.validate(valid =>{
        if (valid) {
          let parames = {
            projectName:this.form.projectName,
            masterId: this.form.masterId
          }
          if(this.currentTab ==='1'){
            parames.templateId = this.templateId
          }else if(this.currentTab ==='2'){
            parames.templateId = this.selectCheck
            //parames.memberList = this.checkedList
          }
          parames.startTime = this.startTime
          parames.endTime = this.endTime
          parames.templateType = this.currentTab
          this.$refs.addForm.resetFields();
          console.log('parames==========', parames)
          this.$emit('ok',parames)
        } else {
          return false;
        }
      })
    },
    changeTemplate(key){
      this.currentTab=key
    },
    checkedTemplate(index){ 
      this.chooseIndex = index
      this.tplImg = window._CONFIG["imgPrefix"] + this.templateSelect[this.chooseIndex].img
      this.tplContent = this.templateSelect[this.chooseIndex].content
      this.templateId = this.templateSelect[this.chooseIndex].id
    },
    getTemplateSelect(){
      getProjectTemplateSelect({}).then(res=>{
          console.log(res)
          this.templateSelect = res.data.data
          this.templateId = this.templateSelect[this.chooseIndex].id
          this.tplImg = window._CONFIG["imgPrefix"] + this.templateSelect[this.chooseIndex].img
          this.tplContent = this.templateSelect[this.chooseIndex].content
      })
    },
    getProjectSelectHandle(){
      getProjectSelect({projectName: this.keyword, pageNo:1, pageSize: 1000}).then(res=>{
        this.projectSelect = res.data.data
        this.selectCheck = this.projectSelect[0].id
      })
    }
  },
  watch:{
    visible(val){
      if(val){
        this.changeMasterList()
        this.getTemplateSelect()
        this.getProjectSelectHandle()
      }
    }
  }
}
</script>

<style lang="less">
.ant-list-items{
  cursor: pointer;
}
.choose{
  padding-left: 10px;
  border-left: 3px solid #4a90e2;
  .ant-list-item-meta-title,.ant-list-item-meta-description{
    color: #4a90e2;
  }
  .ant-list-item-meta-description{
    font-size: 12px;
  }
}
</style>