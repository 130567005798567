import request from "@/utils/request";
import requestFile from "@/utils/requestFile";


// 获取企业用户列表分页
export function getCompanyUserList(parames){
  return request({
    url:'/user/profile/list',
    method:'post',
    data:parames
  })
}

// 获取企业用户列表不分页
export function getCompanyUserListAll(parames){
  return request({
    url:'/user/profile/listAll',
    method:'post',
    data:parames
  })
}
// 获取项目用户列表不分页
export function getProjectUserList(parames){
  return request({
    url:'/project/user/list',
    method:'post',
    data:parames
  })
}

// 图片上传
export function uploadImg(parames){
  return request({
    url:'/project/upload/img-upload',
    method:'post',
    data:parames
  })
}

// 上传方式
export function uploadFile(parames){
  return requestFile({
    url:'/project/upload/file-upload',
    method:'post',
    data:parames
  })
}
/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
 export function downloadFile(url, fileName, params,method) {
  return request({
    url:url,
    method: method? method : "get",
    responseType: 'blob',
    data:params
  }).then((data) => {
    if (!data || data.size === 0) {
      this.warning('文件下载失败')
      return
    }

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data],{type: 'application/x-download'}), fileName)
    }else{
      let url = window.URL.createObjectURL(new Blob([data],{type: 'application/x-download'}))

      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(url); //释放掉blob对象
    }
  })
}
//导出
export function exportFile(url, fileName, params, method) {
  console.log(params)
  return request({
    url:url,
    method: method? method : "get",
    responseType: 'blob',
    data:params
  }).then((data) => {
    if (!data || data.size === 0) {
      this.warning('文件下载失败')
      return
    }
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data],{type: 'application/vnd.ms-excel'}), fileName+'.xlsx')
    }else{
      let url = window.URL.createObjectURL(new Blob([data],{type: 'application/vnd.ms-excel'}))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName+'.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(url); //释放掉blob对象
    }
  })
}
/**
 * 动态查询
 * @param dynamicType 动态类型（0：评论，1：变更记录，2：交付物，3：关联，4：查询）
 * @param projectId 项目ID
 * @param taskId 任务、需求、求助单ID
 * @param taskType 数据类型（0：项目，1：需求 ,2：任务 ,3:缺陷， 4：专家库， 5：成果库，6.求助单,7阶段，8评审点）
 * @returns {*}
 */
export function getTrends(data){
  return request({
    url:'/project/log/selectProjectLog',
    method:'post',
    data
  })
}

// 项目任务需求评论
export function comment(data){
  return request({
    url:'/project/log/add',
    method:'post',
    data
  })
}

//修改项目、阶段、计划、任务、求助单状态（类型(1：计划(项目阶段，评审点)，2：项目，3：任务 4:求助单）
export function updateStatus(data){
  return request({
    url:'/project/plan/update-project',
    method:'post',
    data
  })
}
//云下载
export function coludDownloadFile(parames,downloadUrl) {
  return request({
      url:downloadUrl +'/'+ parames,
      method:'post',
      data:{},
  }).then((req) => {

      if (!req || !req.data || !req.data.filePath) {
          this.warning('文件下载失败')
          return
      }
      let link = document.createElement('a')
      link.style.display = 'none'
      
      link.setAttribute('download', req.data.fileName)
      link.href = req.data.filePath
      link.target ="_blank"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link); //下载完成移除元素

  })
}
//标准库下载
export function standDownloadFile(param) {
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = param
      link.target ="_blank" 
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link); //下载完成移除元素

}

export function standViewFile(parames,downloadUrl) {
  return request({
      url:downloadUrl,
      method:'post',
      data:parames,
  }).then((req) => {

      if (!req || !req.data || !req.data.filePath) {
          this.warning('文件下载失败')
          return
      }
      let link = document.createElement('a')
      link.style.display = 'none'
      
      link.setAttribute('download', req.data.fileName)
      link.href = req.data.filePath
      link.target ="_blank"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link); //下载完成移除元素

  })
}