<template>
  <a-layout class="layout-wrap">
    <!-- 新建项目弹框 -->
    <add-project
      :visible="addProjectVisible"
      :confirm-loading="confirmLoading"
      @ok="addHandleClick"
      @cancel="addProjectVisible = false"
      ref="addProjectForm"
    ></add-project>
    <!-- 新建需求弹窗 -->
    <add-req-form v-if="addReqFormVisible" ref="addReqForm"></add-req-form>

    <a-layout-sider
      class="layout-header-slide"
      v-model="collapsed"
      :trigger="null"
      :class="collapsed ? 'collapsed' : ''"
      collapsible
    >
      <div class="layout-slide-wrap-scroll">
        <div class="logo">
          <img :src="this.$imgBaseUrl + userinfo.logo" alt="" />
          <h2 v-show="!collapsed" style="margin-left: 5px">
            {{ userinfo.companyName }}
          </h2>
        </div>
        <overlay-scrollbars style="height: calc(100% - 150px - 126px)">
          <a-menu
            theme="dark"
            mode="inline"
            :default-selected-keys="[
              $route.meta.defaultMenu ? $route.meta.defaultMenu : $route.name,
            ]"
          >
            <update-pass v-if="updatePassVisible" ref="updatePass" />
            <template v-for="menu in slideMenu">
              <a-menu-item v-if="!menu.hidden" :key="menu.name">
                <router-link :to="menu.path">
                  <a-icon :type="menu.meta.icon" />
                  <span>{{ menu.meta.title }}</span>
                </router-link>
              </a-menu-item>
            </template>
          </a-menu>
        </overlay-scrollbars>

        <div class="bottom">
          <ul>
            <li v-if="configPerm">
              <router-link to="/configure"
                ><a-icon type="setting" /><span v-show="!collapsed"
                  >配置中心</span
                ></router-link
              >
            </li>
            <li v-if="configPermHelp">
              <router-link to="/newStudy"
                ><a-icon type="question-circle" /><span v-show="!collapsed"
                  >帮助与反馈</span
                ></router-link
              >
            </li>
          </ul>
          <p v-show="!collapsed" class="version">当前版本: v{{version}}</p>
        </div>
      </div>
    </a-layout-sider>
    <a-layout class="layout-header-top" style="z-index:999">
      <div class="layout-top">
        <a-layout-header class="header-wrap">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <slot name="headerMenu"></slot>
          <div class="topbar-right-wrap">
            <div class="topbar-right">
              <div class="topbar-item" :class="{ 'search-ui': isSearch }">
                <a-tooltip placement="bottom">
                  <template slot="title">搜索</template>
                  <a-icon
                    type="search"
                    @click="searchShowHandle"
                    style="font-size: 16px"
                  />
                </a-tooltip>
                <input
                  type="text"
                  class="search-input"
                  ref="searchInput"
                  @keypress="searchHandle"
                  placeholder="请输入搜索内容"
                />
              </div>
              <div class="topbar-item">
                <a-tooltip placement="top">
                  <template slot="title">新建项目</template>
                  <a-popover
                    v-model="addVisible"
                    placement="bottomRight"
                    trigger="click"
                  >
                    <a-icon type="plus-circle" style="font-size: 16px" />

                    <div slot="content">
                      <a-menu slot="overlay" style="border-right: none">
                        <a-menu-item key="1">
                          <p @click="createProjrctFormEvt">新建项目</p>
                        </a-menu-item>
                        <a-menu-item key="2">
                          <p @click="createFormEvt">新建需求</p>
                        </a-menu-item>
                      </a-menu>
                    </div>
                  </a-popover>
                </a-tooltip>
              </div>
              <div class="topbar-item" v-if="configPermHelp">
                <a-tooltip placement="bottom">
                  <template slot="title">帮助</template>

                  <a-icon
                    style="font-size: 16px"
                    type="question-circle"
                    @click="() => this.$router.push({ name: 'NewStudy' })"
                  />
                </a-tooltip>
              </div>
              <!-- 消息 -->
              <div class="topbar-item">
                 <a-popover
                  v-model="visible"
                  title="未读消息通知"
                  trigger="click"
                  placement="bottomRight"
                >
                  <template slot="content">
                    <p class="all-msg" @click="allMsg">全部></p>
                    <a-tabs
                      default-active-key="0"
                      style="width: 360px"
                      @change="ckMessage"
                    >
                     <a-tab-pane key="3" tab="工作项">
                        <overlay-scrollbars style="height: 200px">
                          <a-list :data-source="messageList">
                            <a-list-item
                              slot="renderItem"
                              slot-scope="item"
                              @click="goToMessageList(3)"
                            >
                              <a-list-item-meta :description="item.msgContent">
                                <a slot="title">{{ item.titile }}</a>
                              </a-list-item-meta>
                              <div>{{ item.createTime }}</div>
                            </a-list-item>
                          </a-list>
                        </overlay-scrollbars>
                      </a-tab-pane>
                      <a-tab-pane key="1" tab="系统公告">
                        <overlay-scrollbars style="height: 200px">
                          <a-list :data-source="messageList">
                            <a-list-item
                              slot="renderItem"
                              slot-scope="item"
                              @click="goToMessageList(1)"
                            >
                              <a-list-item-meta :description="item.msgContent">
                                <a slot="title">{{ item.titile }}</a>
                              </a-list-item-meta>
                              <div>{{ item.createTime }}</div>
                            </a-list-item>
                          </a-list>
                        </overlay-scrollbars>
                      </a-tab-pane>
                      <a-tab-pane key="2" tab="系统消息">
                        <overlay-scrollbars style="height: 200px">
                          <a-list :data-source="messageList">
                            <a-list-item
                              slot="renderItem"
                              slot-scope="item"
                              @click="goToMessageList(2)"
                            >
                              <a-list-item-meta :description="item.msgContent">
                                <a slot="title">{{ item.titile }}</a>
                              </a-list-item-meta>
                              <div>{{ item.createTime }}</div>
                            </a-list-item>
                          </a-list>
                        </overlay-scrollbars>
                      </a-tab-pane>
                    </a-tabs>
                  </template>
                  <a-badge :dot="unReadMsgCount > 0 || isShow == true">
                    <a-icon type="bell" style="font-size: 16px" @click="unMsg" />
                  </a-badge>
                </a-popover>
              </div>

              <div class="topbar-item" v-if="configPerm">
                <a-tooltip placement="bottom">
                  <template slot="title"> 配置中心 </template>

                  <a-icon
                    type="setting"
                    @click="goSetting"
                    style="font-size: 16px"
                  />
                </a-tooltip>
              </div>

              <div class="topbar-item">
                <a-tooltip placement="top">
                  <template slot="title">个人中心</template>
                  <a-popover
                    v-model="userVisible"
                    placement="bottomRight"
                    trigger="click"
                  >
                    <img src="@/assets/user.png" v-if="image == null" alt="" />
                    <img :src="image" v-else />

                    <div slot="content">
                      <a-menu
                        class="ant-pro-drop-down menu"
                        slot="overlay"
                        style="border-right: none"
                      >
                        <a-menu-item key="1">
                          {{ userinfo.username }}
                        </a-menu-item>
                        <a-menu-item key="2" @click="personalEvt">
                          <a-icon type="user" />
                          个人中心
                        </a-menu-item>
                        <a-menu-item key="3" @click="updatePwdEvt">
                          <a-icon type="lock" />
                          修改密码
                        </a-menu-item>
                        <a-menu-item key="4" @click="handleLogout">
                          <a-icon type="logout" />
                          退出登录
                        </a-menu-item>
                      </a-menu>
                    </div>
                  </a-popover>
                </a-tooltip>
              </div>
            </div>
          </div>
        </a-layout-header>
      </div>
      <!-- 最新消息 -->
      <msg ref="child" @show="show"></msg>
      <a-layout-content class="layout-content-page-wrap">
        <router-view />
        <div class="content-footer">
          <img src="@/assets/logo.png" alt="" />
          <p>硬科技研发管理系统</p>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import headerMenu from "@/components/layout/modules/headerMenu";
import UpdatePass from "./updatepass";
import { unreadMessageCount,getMessageList,getSysLevelVersion } from "../../httpsAPI/search";
import { projectAdd } from "@/httpsAPI/project";
import addProject from "@/views/project/modules/addProject";
import addReqForm from "@/views/requirement/components/taskAdd";
import Msg from "@/components/Msg/index";

export default {
  name: "layout",
  data() {
    return {
      version:'',
      visible: false,
      updatePassVisible: false,
      addReqFormVisible:false,
      collapsed: false,
      slideMenu: [],
      messageList:[],
      currentMenu: ["dashboard"],
      isSearch: false,
      addVisible: false,
      addProjectVisible:false,
      userVisible: false,
      image: null,
      configPerm: false,
      configPermHelp: false,
      confirmLoading: false,
      unReadMsgCount:0, //未读消息
      isShow:null,
      type:'3'
    };
  },
  components: {
    headerMenu,
    UpdatePass,
    addProject,
    addReqForm,
    Msg
  },
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {

  },

  methods: {
    // 版本号
     initData() {
      getSysLevelVersion({
        pageNo: 1,
        pageSize: 10,
      }).then((res) => {
        this.version = res.data.data[0].versionName;
      });
    },
    unMsg(){
      this.initServeMessage(this.type);
    },
    //接受子组件消息状态
    show(show){
      this.isShow = show
    },
    //消息跳转
    allMsg(){
      this.$router.push({ name: "message" });
    },
    goToMessageList(e) {
      this.$router.push({ name: "message", params: { t: e } });
    },
    ckMessage(e) {
      this.type = e
      this.initServeMessage(e);
    },
    initServeMessage(type) {
      getMessageList({ msgCategory: type, pageNo: 1, pageSize: 10,limit:20 }).then(
        (res) => {
          this.messageList = res.data.map((res) => {
            let _res_str = res.createTime.split("-");
            let _res_str2 = `${_res_str[1]}-${_res_str[2]}`;
            let _time = _res_str2.split(":");
            return {
              ...res,
              createTime: `${_time[0]}:${_time[1]}`,
              msgContent:
                res.msgContent &&
                (res.msgContent.length > 30
                  ? `${res.msgContent.slice(0, 30)}...`
                  : res.msgContent),
            };
          });
        }
      );
    },
    //查询消息是否有未读
    unMsgCount(){
      unreadMessageCount().then(res=>{
        this.unReadMsgCount = res.data
      })
    },
    ...mapActions(["Logout"]),
    handleLogout() {
      console.log(this.userinfo.companyId,"this.userinfo.companyId")
      if(this.userinfo.companyId==30 || this.userinfo.companyId=='30'){    
        this.Logout().then(() => {
          this.$router.push({ path: "/v3login" });
        });
      }else if(this.userinfo.companyId==32 || this.userinfo.companyId=='32'){
        this.Logout().then(() => {
          this.$router.push({ path: "/v2login" });
        });
      }else if(this.userinfo.companyId==35 || this.userinfo.companyId=='35'){
        this.Logout().then(() => {
          this.$router.push({ path: "/v4login" });
        });
      }else{
        this.Logout().then(() => {
          this.$router.push({ path: "/login" });
        });
      }
    },
    goSetting() {
      this.$router.push("/configure");
    },
    searchShowHandle() {
      this.isSearch = !this.isSearch;
      this.$refs.searchInput.focus();
    },
    searchHandle(ev) {
      if (ev.code === "Enter") {
        this.$router.push({ name: "search", params: { kw: ev.target.value } });
      }
    },
    personalEvt() {
      this.$router.push({ path: "/personal/settings" });
    },
    updatePwdEvt() {
      this.updatePassVisible = true;
      this.$nextTick(() => {
        this.$refs.updatePass.init();
      });
    },
    // 用户头像
    getInfo() {
      if (this.userinfo.avatar != null) {
        this.image = this.$imgBaseUrl + this.userinfo.avatar;
      }
    },
    // 检查配置中心权限
    checkConfigPerm() {
      const permlist = this.addRouter.filter(
        (item) => item.name === "Configure"
      );
      this.configPerm = permlist.length==0 ? false : true
    },
    // 检查个人中心权限
    checkConfigPermHelp() {
      const permlist = this.addRouter.filter(
        (item) => item.name === "Help"
      );
      this.configPermHelp = permlist.length==0 ? false : true
      console.log(this.configPermHelp,"this.configPermHelp")
    },
    //新建项目
    addHandleClick(parames) {
      this.confirmLoading = true   
      projectAdd(parames).then((res) => {
        this.confirmLoading = false;
        this.addProjectVisible = false;
        if (res.code === "10000") {
          this.$message.success("新建成功");
        } else {
          this.$message.error(`${res.msg}`);
        }
      })
    },
    // 新建项目弹框
    createProjrctFormEvt() {
      this.addVisible = false
      this.addProjectVisible = true
      this.$nextTick(() => {
        this.$refs.addProjectForm.init()
      })
    },
    // 新建需求弹框
    createFormEvt() {
      this.addVisible = false
      this.addReqFormVisible = true
      this.$nextTick(() => {
        this.$refs.addReqForm.init()
      })
    },
  },
  updated(){
    console.log("123",document.getElementsByClassName('ant-layout-content')[0])     
    let dom = document.getElementsByClassName('ant-layout-content')[0];
    dom.scrollTo(0,0); 
  },
  computed: {
    ...mapGetters(["addRouter", "userinfo"]),
  },
  created() {
    this.slideMenu = this.addRouter;
    this.getInfo();
    this.checkConfigPerm();
    this.checkConfigPermHelp();
    this.unMsgCount(3);
    this.initServeMessage(1);
    this.initData();
  },
};
</script>

<style lang="less" scoped>
  .ant-popover-content{
    position: relative;
    .all-msg{
      position: absolute;
      top: 15px;
      right: 24px;
      color: #1890FF;
      cursor:pointer;
    }
  }
.layout-header-slide {
  &.collapsed {
    .bottom {
      width: 80px;
      ul {
        padding-left: 30px;
      }
    }
  }
  .bottom {
    width: 200px;
    height: 150px;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: #001529;
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
    color: rgba(255, 255, 255, 0.65);
    ul {
      width: 100%;
      list-style: none;
      padding-left: 24px;
      li {
        & > a {
          font-size: 14px;
          display: block;
          color: rgba(255, 255, 255, 0.65);
          height: 40px;
          line-height: 40px;
          & > .anticon {
            margin-right: 10px;
            min-width: 14px;
          }
        }
        &:hover {
          a {
            color: #fff;
          }
        }
      }
    }
    .version {
      padding: 0 24px;
    }
  }
}

.topbar-item img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 6px;
}
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}

.ant-pro-account-avatar {
  .antd-pro-global-header-index-avatar {
    margin: ~"calc((64px - 24px) / 2)" 0;
    margin-right: 8px;
    color: #2f54eb;
    vertical-align: top;
    background: rgba(255, 255, 255, 0.85);
  }
}

.menu {
  .anticon {
    margin-right: 8px;
  }

  .ant-dropdown-menu-item {
    min-width: 100px;
  }
}

.content-footer {
  width: 100%;
  height: 60px;
  text-align: center;
  color: #999;
  font-size: 18px;
  margin-top: 20px;
  line-height: 100px;
  img {
    width: 24px;
    height: 24px;
    display: inline-block;
    margin: 0px 10px;
    vertical-align: middle;
  }
  p {
    display: inline-block;
  }
}
.logo {
  display: flex;
  p {
    font-size: 12px;
  }
}
</style>
